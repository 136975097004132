<script>
    import { onMount } from "svelte";
    import { _ } from "svelte-i18n";

    import Alert from "./components/Alert.svelte";
    import Loader from "./components/Loader.svelte";
    import Calendar from "./components/events/Calendar.svelte";
    import EventList from "./components/events/EventList.svelte";
    import Select from "./components/Select.svelte";

    import http from "./utility/http";
    import '../scss/_main.scss';
    import config from 'config';

    let messages = [];

    let events, date;
    let region, regions = [{ label: $_('regions.allRegions'), value: "" }];
    let category, categories = [{ label: $_('category.allEvents'), value: "" }];

    let filteredEvents = [];
    $: if (events) {
        filteredEvents = events.filter(el => (category !== '' ? el.category === category : true) && (region !== '' ? el.region === region : true));
    }

    onMount(async () => {
        const response = await http.getEvents().catch(err => {
            messages = [...messages, {
                type: 'error',
                description: err.message,
                statusCode: (err.response ? err.response.status : 503)
            }];
        });

        if (response && response.data) {
            events = response.data;
            events.forEach(el => {
                if (regions.filter(e => e.value === el.region).length === 0) {
                    regions = [...regions, { label: el.region, value: el.region }];
                }
                if (categories.filter(e => e.value === el.category).length === 0) {
                    categories = [...categories, { label: el.category, value: el.category }];
                }
            });
        }
    });
</script>

<div class="sc__events">
{#each messages as message}
    <Alert {message} />
{/each}

{#if events}
    <div class="sc__events__title">
        <!-- @since 2024-05-09 -->
        {#if config.locale == 'ko'}
            <Select options={regions} bind:value={region} />
            <span>{$_('events.in')}</span>
            <Select options={categories} bind:value={category} />
        {:else if config.locale == 'zh'}
            <span>{$_('events.upcoming')}</span>
            <Select options={regions} bind:value={region} />
            <span>{$_('events.in')}</span>
            <Select options={categories} bind:value={category} />
        {:else}
            <span>{$_('events.upcoming')}</span>
            <Select options={categories} bind:value={category} />
            <span>{$_('events.in')}</span>
            <Select options={regions} bind:value={region} /> 
        {/if}
    </div>
    <div class="sc__events__content">
        <Calendar events={filteredEvents} bind:date />
        <EventList events={filteredEvents} {date} />
    </div>
{:else}
    <Loader />
{/if}
</div>